import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToken } from '../../utils/getToken';
import ResumeAddForm from '../../pages/Candidate/ResumeAdd';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useAuth0 } from '@auth0/auth0-react';


const CandidateBusResume = ({ candidateData }) => {
    const { isAuthenticated } = useAuth0();
    const [resumeItems, setResumeItems] = useState(candidateData || []);
  
    useEffect(() => {
        if (candidateData) {
            setResumeItems(candidateData);
        }
    }, [candidateData]);

  

    useEffect(() => {
    }, [resumeItems]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <>
            {resumeItems.map((resume, index) => {
                return (
                    <div className="resume-item" key={resume.AttachmentID}>
                        <div className="bnasdf_asldf resume-item-read">
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="d-block h5 font-semibold mb-1">{resume.FileName} </h5>
                                </div>

                                <div className="text-end mx-n2 d-flex align-items-center">
                                    <a href={resume.Uri} target="_blank" rel="noreferrer" className="download-but btn me-2-15"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>

    );

}

export default CandidateBusResume;
