import { useState, useEffect } from 'react';
import { getApiBaseUrl } from '../utils/Config';

function useCandidateData(token, idTokenClaims) {
    const [apiCandidateID, setCandidateID] = useState(null);
    const [apiDataCandidate, setCandidateFull] = useState(null);
    const [apiDataDetails, setCandidateDetails] = useState(null);
    const [apiDataPreferences, setCandidatePreferences] = useState(null);
    const [apiDataSummary, setCandidateSummary] = useState(null);
    const [apiDataSocial, setCandidateSocial] = useState(null);
    const [apiDataExperience, setCandidateExperience] = useState(null);
    const [apiDataEducation, setCandidateEducation] = useState(null);
    const [apiDataSkill, setCandidateSkill] = useState(null);
    const [apiData, setCandidate] = useState(null);
    const [success, setSuccess] = useState(false);
    const [fetching, setFetching] = useState(false); // Add fetching state
    const [refetchTrigger, setRefetchTrigger] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const baseUrl = getApiBaseUrl();

    useEffect(() => {
        if (token) {
            const fetchPost = async () => {
                setFetching(true); // Start fetching
                setIsLoading(true); // Start loading
                try {
                    const response = await fetch(
                        `${baseUrl}/api/Candidate/GetCandidateCurriculumVitae?id=${idTokenClaims.sub}`,
                        {
                            mode: 'cors',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );
                    const data = await response.json();
                    setSuccess(data.success);
                    setCandidateID(data.result?.Candidate?.CandidateID || null);
                    setCandidate(data.result?.Candidate || null);
                    setCandidateFull(data.result || null);
                    setCandidateDetails(data.result?.CandidateDetails || null);
                    setCandidatePreferences(data.result?.Preference || null);
                    setCandidateSummary(data.result?.Summary || null);
                    setCandidateSocial(data.result?.Social || null);
                    setCandidateExperience(data.result?.Experience || null);
                    setCandidateEducation(data.result?.Education || null);
                    setCandidateSkill(data.result?.Skill || null);
                } catch (error) {
                    setSuccess(false);
                } finally {
                    setFetching(false); // Stop fetching
                    setIsLoading(false); // Stop loading
                }
            };

            fetchPost();
        }
    }, [token, refetchTrigger, baseUrl, idTokenClaims]);

    return {
        apiCandidateID,
        apiDataCandidate,
        apiDataDetails,
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiData,
        setRefetchTrigger,
        success,
        fetching, // Return fetching state
        isLoading
    };
}

export default useCandidateData;
