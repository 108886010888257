import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext';
import { useParams } from 'react-router-dom';
import CandidateResumeApply from '../../pages/Candidate/CandidateResumeApply';
import { CandidateContext } from '../../Context/CandidateContext';
import { getApiBaseUrl } from '../../utils/Config';
import { useAuth0 } from '@auth0/auth0-react';

function JobApply() {
    const { isAuthenticated } = useAuth0();

    const {
        apiCandidateID,
        apiCandidateResume,
        apiCandidateCover, apiDataDetails, refetchCandidateDetails
    } = useContext(CandidateContext);
    const [showProfilePic, setShowProfilePic] = useState(true);
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { token, idTokenClaims } = useContext(TokenContext);
    const baseUrl = getApiBaseUrl();

    useEffect(() => {
        const fetchJob = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetch(`${baseUrl}/api/JobPostPublic/Get?jobPostId=${encodeURIComponent(jobId)}`);
                if (!response.ok) throw new Error('Error fetching job');
                const data = await response.json();
                setJob(data.result);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJob();
    }, [jobId]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (isLoading) return <p className="loadingStyle">Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!job) return <p>No job data found</p>;


    return (
        <>
            <div className="py-5 vstack gap-6 mt-auto container-xxl">
                <div className="container-fluid">
                    <header>
                        <div className="pt-6">
                            <h3 className="h4 me-auto">Applying for position: </h3>
                            <h3 className="h2 me-auto">{job.Title}</h3>
                            <h3 className="h4 me-auto">{job.BusinessName} </h3>
                        </div>
                    </header>

                    <CandidateResumeApply refetchCandidateDetails={refetchCandidateDetails} apiDataDetails={apiDataDetails} candidateID={apiCandidateID} candidateJobTitle={job.Title} candidateBusName={job.BusinessName} candidateBusID={job.BusinessID} candidateDataResume={apiCandidateResume} candidateDataCover={apiCandidateCover} jobQuestions={job.SelectedQuestions} />
                </div>
            </div>

        </>
    );
}

export default JobApply;
