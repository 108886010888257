import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getApiBaseUrl } from '../utils/Config';

const AuthCallback = () => {
    const { getAccessTokenSilently, isAuthenticated, user, logout,isLoading } = useAuth0();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    
    const stateParam = new URLSearchParams(window.location.search).get('state');
    const restateParam = new URLSearchParams(window.location.search).get('restate');
    const errorParam = new URLSearchParams(window.location.search).get('error');
    const errorDescription = new URLSearchParams(window.location.search).get('error_description');
   
    useEffect(() => {
        if (errorParam) {
            setError(errorDescription || 'An unknown error occurred.');
            setTimeout(() => {
                logout({ returnTo: window.location.origin });
            }, 2000);
            return;
        }

        const checkUserExistence = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently();
                    const response = await checkIfCandidateExists(user.sub, token);
                    console.log("checking api", response);

                    if (response.error === 'API_UNREACHABLE') {
                        setError('Unable to connect to the server. You will be logged out. Please try again later.');
                        setTimeout(() => {
                            logout({ returnTo: window.location.origin });
                        }, 2000); // Add a short delay for the user to see the error message
                        return;
                    }

                    const targetUrl = restateParam || '/candidate/profile';
                    if (response.result) {
                        navigate(targetUrl);
                    } else {
                        navigate('/candidate/additional-info');
                    }
                } catch (error) {
                    console.error('Unexpected error:', error);
                    setError('Something went wrong during authentication. You will be logged out. Please try again later.');
                    setTimeout(() => {
                        logout({ returnTo: window.location.origin });
                    }, 2000); // Handle unexpected errors gracefully
                }
            }
        };

        checkUserExistence();
    }, [isAuthenticated, getAccessTokenSilently, user, navigate, stateParam, errorParam, errorDescription, logout]);

    const checkIfCandidateExists = async (b2cObjectId, token) => {
        try {
            const response = await fetch(
                `${getApiBaseUrl()}/api/Authentication/DoesCandidateExist?b2cObjectId=${b2cObjectId}`,
                {
                    mode: 'cors',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );

            if (!response.ok) {
                throw new Error('API Error');
            }
            const data = await response.json();
            return { success: data.success, result: data.result };
        } catch (error) {
            console.error('API connection failed:', error);
            return { success: false, error: 'API_UNREACHABLE' };
        }
    };

 

    return (
        <div className="loadingStyle">
            {error ? <p>{error}</p> : <p>Loading...</p>}
        </div>
    );
};

export default AuthCallback;
