import React, { useState,useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';
import { CandidateContext } from '../../Context/CandidateContext';

function ExperienceForm({ candidateData, onCancel, handleUpdate, hasCurrent }) {
    const { isAuthenticated } = useAuth0();
    const { refetchCandidateDetails } = useContext(CandidateContext)
    const { token, idTokenClaims } = useContext(TokenContext);
    const [saving, setSaving] = useState(false);
    const { showAlert } = useContext(AlertContext);

    const [formData, setFormData] = useState({
        Title: candidateData.Title || '',
        Company: candidateData.Company || '',
        Location: candidateData.Location || '',
        StartDate: candidateData.StartDate || '',
        EndDate: candidateData.EndDate || '',
        Current: candidateData.Current === 'true' || candidateData.Current === true,
        About: candidateData.About || '',
        ExperienceID: candidateData.ExperienceID?.toString() || ''
    });
    const [errors, setErrors] = useState({
        Title: '',
        Company: '',
        Location: '',
        StartDate: '',
        EndDate: '',
        Current: '',
        About: '',
    });
    const handleInputChange = (event, fieldName) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };
    const baseUrl = getApiBaseUrl();

    const formattedStartDate = formData.StartDate ? new Date(formData.StartDate).toISOString().split('T')[0] : '';
    const formattedEndDate = formData.EndDate ? new Date(formData.EndDate).toISOString().split('T')[0] : '';

    //Validation
    const validate = () => {
        const newErrors = {};

        const specialCharRegex = /[^a-zA-Z0-9\s]/; // A regex pattern to check for special characters.

        const formattedStartDate = formData.StartDate ? new Date(formData.StartDate).toISOString().split('T')[0] : '';
        const formattedEndDate = formData.EndDate ? new Date(formData.EndDate).toISOString().split('T')[0] : '';

        // Check for required fields
        if (!formData.Title) newErrors.Title = 'Title is required.';
        if (!formData.Company) newErrors.Company = 'Company is required.';
        if (!formData.Location) newErrors.Location = 'Location is required.';
        if (!formData.StartDate) newErrors.StartDate = 'Start date is required.';

        // Disallow special characters
        if (formData.Title && specialCharRegex.test(formData.Title)) newErrors.Title = 'Special characters are not allowed.';
        if (formData.Company && specialCharRegex.test(formData.Company)) newErrors.Company = 'Special characters are not allowed.';
        if (formData.Location && specialCharRegex.test(formData.Location)) newErrors.Location = 'Special characters are not allowed.';

        if (!formData.Current && !formData.EndDate) {
            newErrors.EndDate = 'End date is required.';
        }
        // Check if end date is before start date
        if (formData.StartDate && formData.EndDate && new Date(formattedStartDate) > new Date(formattedEndDate)) {
            newErrors.EndDate = 'End date should be after start date.';
        }
        if (hasCurrent && formData.Current) {
            newErrors.Current = 'You can only have one current role.';
        }
        // If Current Role is checked, EndDate shouldn't be required or considered
        if (formData.Current) {
            delete newErrors.EndDate;
        }

        // Length constraints for Title, Company, Location, and About
        if (formData.Title && formData.Title.length > 100) {
            newErrors.Title = 'Title should not exceed 100 characters.';
        }
        if (formData.Company && formData.Company.length > 100) {
            newErrors.Company = 'Company should not exceed 100 characters.';
        }
        if (formData.Location && formData.Location.length > 100) {
            newErrors.Location = 'Location should not exceed 100 characters.';
        }
        if (formData.About && formData.About.length > 1000) {
            newErrors.About = 'Description should not exceed 1000 characters.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };

    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);

            const dataToPost = {
                Title: formData.Title,
                Company: formData.Company,
                Location: formData.Location,
                StartDate: formData.StartDate,
                EndDate: formData.EndDate,
                Current: formData.Current,
                About: formData.About,
                // Set other properties here
                CandidateID: candidateData.CandidateID,
                ExperienceID: candidateData.ExperienceID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: idTokenClaims.oid,
            };
            if (!formData.Current && formData.EndDate) {
                dataToPost.EndDate = formData.EndDate;
            }
            try {
                const response = await fetch(
                    `${baseUrl}/api/Experience/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();
                if (responseData.success) {
                    showAlert('Success', true);
                    refetchCandidateDetails(); 
                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                console.log('Fetch error:', error); 

                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    return (
        <div className="experience-create-form">
            <form onSubmit={handleSubmit}>
                <div className="experience-item-edit">
                    <div className="experience-edit card-body-iajj">
                        <div className="row g-5">
                            <div className="col-md-6">
                                <div>
                                    <label className="form-label">Job Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formData.Title}
                                        onChange={e => handleInputChange(e, 'Title')} />
                                    {errors.Title && (
                                        <small className="text-danger">{errors.Title}</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <label className="form-label">Company</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formData.Company}
                                        onChange={e => handleInputChange(e, 'Company')} />
                                    {errors.Company && (
                                        <small className="text-danger">{errors.Company}</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <label className="form-label">Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={formData.Location}
                                        onChange={e => handleInputChange(e, 'Location')} />
                                    {errors.Location && (
                                        <small className="text-danger">{errors.Location}</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <label className="form-label">Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control flatpickr-input active"
                                        value={formattedStartDate}
                                        onChange={e => handleInputChange(e, 'StartDate')} />
                                    {errors.StartDate && (
                                        <small className="text-danger">{errors.StartDate}</small>
                                    )}
                                </div>
                            </div>
                            {!formData.Current && (
                                <div className="col-md-6">
                                    <div>
                                        <label className="form-label">Completion Date</label>
                                        <input
                                            type="date"
                                            className="form-control flatpickr-input active"
                                            value={formattedEndDate}
                                            onChange={e => handleInputChange(e, 'EndDate')} />
                                        {errors.EndDate && (
                                            <small className="text-danger">{errors.EndDate}</small>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="col-md-6">
                                <label className="form-label">Current Role</label>
                                <div className="form-check form-check-linethrough">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={formData.Current}
                                        onChange={e => handleInputChange(e, 'Current')} />
                                    {errors.Current && (
                                        <small className="text-danger">{errors.Current}</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        value={formData.About}
                                        onChange={e => handleInputChange(e, 'About')}></textarea>
                                    {errors.About && (
                                        <small className="text-danger">{errors.About}</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 text-end">
                                <button type="submit" className="btn btn-sm btn-primary me-2 add-experience" id="add-experience" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                <button type="button" className="btn btn-sm btn-neutral me-2 cancel-add-experience" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ExperienceForm;
