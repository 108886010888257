import { useState, useEffect } from 'react';
import { getApiBaseUrl } from '../utils/Config';

function useCertificate(token, apiCandidateID, refetchTrigger) {
    const [certificate, setCertificate] = useState(null);
    const baseUrl = getApiBaseUrl();
    const [isCertLoading, setIsCertLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (token && apiCandidateID) {
            const fetchCertificate = async () => {
                setIsCertLoading(true);
                try {
                    const response = await fetch(
                        `${baseUrl}/api/Attachment/GetCandidateCertificate?candidateId=${apiCandidateID}`,
                        {
                            mode: 'cors',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );
                    if (!response.ok) {
                        throw new Error("Failed to fetch certificate");
                    }
                    const data = await response.json();
                    setCertificate(data.result);
                } catch (error) {
                    console.error("Failed to fetch certificate URL:", error);
                } finally {
                    setIsCertLoading(false);
                }
            };

            fetchCertificate();
        }
    }, [token, apiCandidateID, baseUrl, refetchTrigger]);

    return {
        certificate,
        isCertLoading,
    };
}

export default useCertificate;
