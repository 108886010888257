import React, { useState, useContext, useEffect } from 'react';
import { CandidateContext } from '../../Context/CandidateContext';
//Import Pages
import CandidatePersonalInfo from '../../pages/Candidate/CandidatePersonalInfo';
import CandidateProfilePicture from '../../pages/Candidate/CandidateProfilePicture';
import CandidatePersonalDetails from '../../pages/Candidate/CandidatePersonalDetails';
import CandidatePreferences from '../../pages/Candidate/CandidatePreferences';
import CandidateSummary from '../../pages/Candidate/CandidateSummary';
import CandidateSocials from '../../pages/Candidate/CandidateSocials';
import CandidateExperience from '../../pages/Candidate/CandidateExperience';
import CandidateEducation from '../../pages/Candidate/CandidateEducation';
import CandidateSkill from '../../pages/Candidate/CandidateSkills';
import CandidateResume from '../../pages/Candidate/CandidateResume';
import CandidateCertificate from '../../pages/Candidate/CandidateCertificate';
import Alert from '../../utils/GenAlert';
import { useAuth0 } from '@auth0/auth0-react';
import SkeletonLoader from '../../utils/SkeletonLoader';

function Profile() {
    const { isAuthenticated } = useAuth0();
    useEffect(() => {
        document.title = "Edit Candidate Profile | TalentUp";
    }, []);
    const {
        apiData,
        apiDataDetails,
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiCandidateID,
        profilePicUrl,
        apiCandidateResume,
        apiCandidateCertificate, isLoading, isCertLoading, isResumeLoading
    } = useContext(CandidateContext);
    const [showProfilePic, setShowProfilePic] = useState(true);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <div className="flex-lg-1 h-screen overflow-y-lg-auto profile-lalk_lo">
            <header className="bg-surface-secondary">
                <div className="dashboad-outer-cont">
                    <div className="container-fluid max-w-screen-xl mt-7">
                        {isLoading ? <SkeletonLoader /> :
                            <div className="row g-6">
                                <div className="col-xl-12 vstack gap-6 oaosdhfk">
                                    <div className="card">
                                        <div className="card-body persInfoCard">
                                            <div className="row g-0">
                                                <div className="col-auto">
                                                    {showProfilePic && profilePicUrl && <CandidateProfilePicture candidateData={apiData} candidateID={apiCandidateID} profilePic={profilePicUrl} />}
                                                </div>
                                                <div className="col ps-4 pt-4">
                                                    <CandidatePersonalInfo candidateData={apiData} profilePicControl={{ hide: () => setShowProfilePic(false), show: () => setShowProfilePic(true) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 vstack oaosdhfk gap-6 mt-7">
                                    <div className="card">
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin PdetailsCard">
                                                <CandidatePersonalDetails candidateID={apiCandidateID} candidateData={apiDataDetails} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </header>

            <main className="py-6 bg-surface-secondary">
                <div className="container-fluid max-w-screen-xl">
                    <div className="row g-6">
                        <div className="col-xl-12">
                            {isLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 pref-create-k_idj">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Your Preferences</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Pref">
                                                <CandidatePreferences candidateID={apiCandidateID} candidateData={apiDataPreferences} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 summary-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Your Summary</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Summary">
                                                <CandidateSummary candidateID={apiCandidateID} candidateData={apiDataSummary} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 social-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Social</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-soc">
                                                <CandidateSocials candidateID={apiCandidateID} candidateData={apiDataSocial} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isLoading ? <SkeletonLoader /> :

                                <div className="vstack gap-6 education-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Work Experience</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Exp">
                                                <CandidateExperience candidateID={apiCandidateID} candidateData={apiDataExperience} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 education-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Education History</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Edu">
                                                <CandidateEducation candidateID={apiCandidateID} candidateData={apiDataEducation} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 skills-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Skills</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Skill">
                                                <CandidateSkill candidateID={apiCandidateID} candidateData={apiDataSkill} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isCertLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 certificate-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Certificate</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Certificate">
                                                <CandidateCertificate candidateID={apiCandidateID} candidateData={apiCandidateCertificate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isResumeLoading ? <SkeletonLoader /> :
                                <div className="vstack gap-6 resume-create-k_idj mt-7">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="mb-3">Resume</h5>
                                        </div>
                                        <div className="card-body-askop">
                                            <div className="hausld ldfin hausld-Resume">
                                                <CandidateResume candidateID={apiCandidateID} candidateData={apiCandidateResume} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Profile;
