import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TokenContext } from '../../Context/TokenContext';
import ExperienceForm from './ExperienceForm';
import ExperienceAddForm from '../../pages/Candidate/ExperienceAdd';
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';
import { CandidateContext } from '../../Context/CandidateContext';

const CandidateExperience = ({ candidateID, candidateData }) => {
    const { isAuthenticated } = useAuth0();
    const { token, idTokenClaims } = useContext(TokenContext);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [experienceItems, setExperienceItems] = useState(candidateData || []);
    const [experienceIdToDelete, setExperienceIdToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [hasCurrent, setHasCurrent] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);
    const { refetchCandidateDetails } = useContext(CandidateContext)


    const handleEditClick = (index) => {
        setEditingIndex(index);
    };

    const handleCancelClick = () => {
        setEditingIndex(null);
    };
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };

    const updateExperienceItems = (newExperienceItem) => {
        setExperienceItems((prevItems) => [...prevItems, newExperienceItem]);
        setShowAddForm(false);
        if (newExperienceItem.Current) {
            setHasCurrent(true);
        }
    };
    useEffect(() => {
        if (candidateData) {
            setExperienceItems(candidateData);
        }
    }, [candidateData]);

    const handleUpdate = (updatedItem) => {
        setExperienceItems(prevItems => prevItems.map(item => item.ExperienceID === updatedItem.ExperienceID ? updatedItem : item));
        setEditingIndex(null);
        if (updatedItem.Current) {
            setHasCurrent(true);
        }
    };
    const handleDelete = async (ExperienceId) => {
        if (deleting) {
            return;
        }
        setDeleting(true);

        try {
            const response = await fetch(
                `${baseUrl}/api/Experience/Delete?ExperienceId=${ExperienceId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseData = await response.json();
            if (responseData.success) {
                //setExperienceItems(prevItems => {
                //    const newItems = prevItems.filter(item => item.ExperienceID !== ExperienceId);
                //    const currentExists = newItems.some(exp => exp.Current);
                //    setHasCurrent(currentExists);
                //    return newItems;
                //});
                showAlert('Item deleted successfully.', true);
                refetchCandidateDetails(); 

            }
            else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            console.error('There was an error deleting the item:', error);
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setDeleting(false);
            setExperienceIdToDelete(null);
        }
    };
    useEffect(() => {
        if (candidateData) {
            setExperienceItems(candidateData);
            // Check if any experience is current and update hasCurrent state accordingly
            const currentExists = candidateData.some(exp => exp.Current);
            setHasCurrent(currentExists);
        }
    }, [candidateData]);

    const handleShowMoreExperience = (experienceId) => {
        const detailsElement = document.querySelector(`.exp-${experienceId}-toggle-details`);
        if (detailsElement.style.display === 'none') {
            detailsElement.style.display = 'block';
        } else {
            detailsElement.style.display = 'none';
        }
    };

    useEffect(() => {
    }, [experienceItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            {experienceItems.map((experience, index) => {
                return (
                    <div className="experience-item" key={experience.ExperienceID}>
                        {editingIndex === index ? (
                            <ExperienceForm
                                candidateData={experience}
                                candidateID={candidateID}
                                token={token}
                                onCancel={handleCancelClick}
                                handleUpdate={handleUpdate}
                                hasCurrent={hasCurrent}
                            />
                        ) : (
                            <div className="bnasdf_asldf experience-item-read">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div>
                                        {experience.Current && (
                                            <div className={`currentRoleContainer-${index}`}>
                                                <div className="MuiBox-root current-Title-la">
                                                    <span className="badge bg-success text-success exp-current-flag" style={{ verticalAlign: 'top' }}>Current Role</span>
                                                </div>
                                            </div>
                                        )}

                                        <div className={`Exp-Title`}>
                                            <h5 className="d-block h5 font-semibold mb-1">{experience.Title}</h5>
                                        </div>
                                        <h5 className="d-block h5 mb-1 h5company" style={{ fontWeight: 700 }}>{experience.Company}</h5>

                                        {experience.Current ? (
                                            <h3 className="d-block text-sm text-muted">
                                                {new Date(experience.StartDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })} to Current
                                            </h3>
                                        ) : (
                                            <h3 className="d-block text-sm text-muted">
                                                {new Date(experience.StartDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })} to {new Date(experience.EndDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })}
                                            </h3>
                                        )}

                                        {!experience.About ? null : (
                                            <>
                                                <button className="btn btn-inner-hidden as_ksk_iu bi-chevron-down" onClick={() => handleShowMoreExperience(experience.ExperienceID)} data-expid={experience.ExperienceID}>More</button>
                                                <div className={`experience-show-details exp-${experience.ExperienceID}-toggle-details ${index}-exp-item-sd`} style={{ display: 'none' }}>
                                                    {experience.About}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="text-end mx-n2 d-flex align-items-center">
                                        <button type="button" className="btn me-2-15 edit-experience edit-but" onClick={() => handleEditClick(index)}></button>
                                        <button className="btn me-2-15 delete-but" data-bs-target="#deleteexpConfirm" onClick={() => setExperienceIdToDelete(experience.ExperienceID)}></button>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                );
            })}
            {showAddForm && (
                <ExperienceAddForm
                    candidateID={candidateID}
                    token={token}
                    onCancel={handleAddCancelClick}
                    updateExperienceItems={updateExperienceItems}
                    hasCurrent={hasCurrent}
                />
            )}
            <div className="text-center experience-create-kksj">
                <button className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)}><i className="bi bi-plus"></i></button>
            </div>
            <Modal show={experienceIdToDelete !== null} onHide={() => setExperienceIdToDelete(null)} centered>
                <Modal.Header>
                    <div className="icon icon-shape rounded-3 bg-soft-primary text-danger text-lg me-4">
                        <i className="bi bi-trash"></i>
                    </div>
                    <Modal.Title>Delete, Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(experienceIdToDelete)} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete Now'}
                    </Button>
                    <Button variant="neutral" onClick={() => setExperienceIdToDelete(null)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>

    );

}

export default CandidateExperience;
