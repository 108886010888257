import React, { useState, useEffect,useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { CandidateContext } from '../../Context/CandidateContext';
import { useAuth0 } from '@auth0/auth0-react';

const CandidatePersonalInfo = ({ candidateData, profilePicControl }) => {
    const { isAuthenticated,user } = useAuth0();
    const { token, idTokenClaims } = useContext(TokenContext);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [countryCode, setCountryCodeResult] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);
    const { refetchCandidateDetails } = useContext(CandidateContext)
    const [countryCodeDisplay, setCountryCodeDisplay] = useState('');
    const handleEditClick = () => {
        setEditing(true);
        profilePicControl.hide();
    };

    const handleCancelClick = () => {
        setEditing(false);
        profilePicControl.show();
    };
    const [formData, setFormData] = useState({
        FirstName: "",
        LastName: "",
        MobileNumber: "",
        CountryCodeID: "",
    });
    const [errors, setErrors] = useState({
        FirstName: '',
        LastName: '',
        MobileNumber: '',
        CountryCodeID: '',
    });
    useEffect(() => {
        if (candidateData) {
            setFormData({
                FirstName: candidateData.FirstName,
                LastName: candidateData.LastName,
                MobileNumber: candidateData.MobileNumber,
                Email: candidateData.Email,
                CountryCodeID: candidateData.CountryCodeID, 
            });
            //const selectedText = e.target.options[e.target.selectedIndex].text;
            //setCountryCodeDisplay(selectedText);
        }
    }, [candidateData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "CountryCodeID") {
            const selectedText = e.target.options[e.target.selectedIndex].text;
            setCountryCodeDisplay(selectedText);
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };



    //Validation
    const validate = () => {
        const newErrors = {
            FirstName: formData.FirstName ? (
                /[^a-zA-Z\s]/.test(formData.FirstName)
                    ? 'First name should not have special characters.'
                    : (formData.FirstName.length > 50)
                        ? 'First name should not exceed 50 characters.'
                        : ''
            ) : 'First name is required.',

            LastName: formData.LastName ? (
                /[^a-zA-Z\s]/.test(formData.LastName)
                    ? 'Last name should not have special characters.'
                    : (formData.LastName.length > 50)
                        ? 'Last name should not exceed 50 characters.'
                        : ''
            ) : 'Last name is required.',

            // Updated validation for MobileNumber
            MobileNumber: formData.MobileNumber && !/^\d+$/.test(formData.MobileNumber)
                ? 'Please enter a valid mobile number.'
                : '',
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };

    useEffect(() => {
        const getCountryCodes = async () => {
            if (token) {
                try {
                    const response = await fetch(
                        `${baseUrl}/api/LookUp/GetCountryCode`,
                        {
                            mode: 'cors',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setCountryCodeResult(data.result);

                } catch (error) {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                } finally {
                    
                }
            }
        };
        getCountryCodes();
    }, [token, idTokenClaims]);
    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);

            const dataToPost = {
                CandidateID: candidateData.CandidateID,
                Email: candidateData.Email,
                FirstName: formData.FirstName,
                LastName: formData.LastName,
                CountryCodeID: formData.CountryCodeID,
                MobileNumber: formData.MobileNumber,
                // Set other properties here
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: user.sub,
                Status: true
            };


            try {
                const response = await fetch(
                    `${baseUrl}/api/Candidate/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();

                if (responseData.success) {
                    showAlert('Success', true);
                    refetchCandidateDetails(); 

                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }

    };
    useEffect(() => {
        if (countryCode && formData.CountryCodeID) {
            const matchingCode = countryCode.find(code => code.ID.toString() === formData.CountryCodeID.toString());
            if (matchingCode) {
                setCountryCodeDisplay(matchingCode.Full); 
            }
        }
    }, [countryCode, formData.CountryCodeID]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <div className="hausld ldfin">
            <div className="personInfo-item">
                {editing ? (
                    <form id="personInfo-form">
                        <div className={`personInfo-item-${editing ? 'edit' : 'read'}`}>
                            <div className="personInfo-edit card-body-iajj">

                                <input type="hidden" className="form-control" defaultValue={formData.CandidateID} name="candidateID" readOnly></input>
                                <div className="row g-5">
                                    <div className="col-md-6">
                                        <div>
                                            <label className="form-label">First Name</label>
                                            <input type="text" className="form-control" value={formData.FirstName} name="FirstName" onChange={handleInputChange}></input>
                                            {errors.FirstName && (
                                                <small className="text-danger">{errors.FirstName}</small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div>
                                            <label className="form-label">Last Name</label>
                                            <input type="text" className="form-control" value={formData.LastName} name="LastName" onChange={handleInputChange}></input>
                                            {errors.LastName && (
                                                <small className="text-danger">{errors.LastName}</small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div>
                                                    <label className="form-label">Phone number</label>
                                                    <select
                                                        className="form-select"
                                                        id="countryCodeSelect"
                                                        name="CountryCodeID" 
                                                        value={formData.CountryCodeID || ''}
                                                        onChange={handleInputChange}>
                                                        <option value="" disabled>Select country code</option>
                                                        {countryCode && countryCode.map((code, index) => ( 
                                                            <option key={index} value={code.ID}>{code.Full}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-md-6 align-self-end">
                                                <div className="input-group position-relative">
                                                    <span className="input-group-text countr_su" id="countryCodeSpan"> {countryCodeDisplay}</span>
                                                    <input type="text" className="form-control" value={formData.MobileNumber} name="MobileNumber" onChange={handleInputChange}></input>
                                                </div>
                                            </div>
                                            {errors.MobileNumber && (
                                                <small className="text-danger">{errors.MobileNumber}</small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <label className="form-label">Email Address</label>
                                            <input type="email" className="form-control" defaultValue={formData.Email} name="Email" readOnly></input>
                                        </div>
                                    </div>

                                    <div className="col-12 text-end">
                                        <button type="button" className="btn btn-sm btn-primary me-2 save-personInfo" id="save-personInfo" onClick={handleSubmit} disabled={saving}> {saving ? 'Saving...' : 'Save'}</button>
                                        <button type="button" className="btn btn-sm btn-neutral me-2 cancel-personInfo" onClick={handleCancelClick}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                ) : (
                    <div className={`bnasdf_asldf personInfo-item-${editing ? 'edit' : 'read'}`}>
                        <div className="dgfsdfgsdfg">
                            <h1 className="h3">
                                <span className="personInfo-fn">{formData.FirstName} </span> <span className="personInfo-ln">{formData.LastName}</span>
                            </h1>
                                <p className="personInfo_mn">
                                    {countryCodeDisplay} {formData.MobileNumber}
                            </p>
                            <p className="personInfo_em">
                                {formData.Email}
                            </p>
                        </div>
                    </div>
                )}

                {!editing && (
                    <span className="edit-icon">
                        <button type="button" className="btn px-2 edit-personInfo edit-but" onClick={handleEditClick}></button>
                    </span>
                )}
            </div>
        </div>
    );
}

export default CandidatePersonalInfo;
