// Header.js
import React from 'react';
import logoImage from '../../src/images/Logo1-square2-white-transparent.png';

function HomeFooter() {
    return (
        <div className="g-6 foo_ls_lskui">
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h5>Candidates</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">About</a></li>
                        </ul>
                    </div>
                    <div className="col-sm">
                        <h5>Employers</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">About</a></li>
                        </ul>
                    </div>
                    <div className="col-sm">
                        <h5>TalentUp</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Home</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Features</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Pricing</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">FAQs</a></li>
                            <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">About</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 order-md-first">
                        <img src={logoImage} className="footer-logo" alt="Talentup - The IT job marketplace" />
                    </div>
                    <div className="d-flex flex-column justify-content-between py-2 my-1 border-top text-center">
                        <p>
                            &copy; 2023 - Talentup - <a href="#">Privacy</a>
                        </p>
                    </div>
                    <div className="social-footer social-footer-inner text-center">
                        <a className="link-body-emphasis px-1" href="#"><i className="bi bi-facebook"></i></a>
                        <a className="link-body-emphasis px-1" href="#"><i className="bi bi-instagram"></i></a>
                        <a className="link-body-emphasis px-1" href="#"><i className="bi bi-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeFooter;
