import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import useCandidateCV from '../hooks/useCandidateCV';
import useProfilePic from '../hooks/useProfilePic';
import useResume from '../hooks/useResume';
import useCoverLetter from '../hooks/useCoverLetter';
import useCertificate from '../hooks/useCertificate';
import useGetJobApps from '../hooks/useGetJobApps';
import { useAuth } from '../Context/TokenContext';

const CandidateContext = createContext();

export const useCandidate = () => {
    return useContext(CandidateContext);
};

const CandidateProvider = ({ children }) => {
    const { token, idTokenClaims } = useAuth();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSuccess, setAlertSuccess] = useState(true);

    // General candidate data trigger
    const {
        apiCandidateID,
        apiDataDetails,
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiData,
        apiDataCandidate, isLoading, setRefetchTrigger, fetching,
        success
    } = useCandidateCV(token, idTokenClaims);

    // Resume-specific trigger
    const [refetchTriggerResume, setRefetchTriggerResume] = useState(0);

    // Certificate-specific trigger
    const [refetchTriggerCert, setRefetchTriggerCert] = useState(0);

    const refetchCandidateDetails = useCallback(() => {
        setRefetchTrigger(prev => prev + 1);
    }, [setRefetchTrigger]);

    const refetchCandidateResume = useCallback(() => {
        setRefetchTriggerResume(prev => prev + 1);
    }, [setRefetchTriggerResume]);

    const refetchCandidateCert = useCallback(() => {
        setRefetchTriggerCert(prev => prev + 1);
    }, [setRefetchTriggerCert]);

    const profilePicUrl = useProfilePic(token, apiCandidateID);
    const { resume: apiCandidateResume, isResumeLoading } = useResume(token, apiCandidateID, refetchTriggerResume);
    const apiCandidateCover = useCoverLetter(token, apiCandidateID);
    const { certificate: apiCandidateCertificate, isCertLoading } = useCertificate(token, apiCandidateID, refetchTriggerCert);
    const apiCandidateJobs = useGetJobApps(token, apiCandidateID);

    const value = {
        apiCandidateID,
        apiDataDetails,
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiData,
        apiDataCandidate,
        profilePicUrl,
        apiCandidateResume,
        apiCandidateCover,
        apiCandidateJobs,
        apiCandidateCertificate,
        isLoading,
        isCertLoading,
        isResumeLoading,
        refetchCandidateDetails,
        refetchCandidateCert,
        refetchCandidateResume,
        fetching,
        success
    };

    return (
        <CandidateContext.Provider value={value}>
            {children}
        </CandidateContext.Provider>
    );
};

export { CandidateProvider, CandidateContext };
