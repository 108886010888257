import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CandidateLayout from './pages/Candidate/CandidateLayout';
import CandidateProfile from './pages/Candidate/Profile';
import CandidateBusinessView from './pages/Candidate/BusinessView';
import CandidateJobsView from './pages/Candidate/Jobs';
import CandidateJobApply from './pages/Candidate/JobApply';
import NotFound from './pages/404';
import ErrorPage from './pages/ErrorPage';
import CandidateSettingsView from './pages/Candidate/Settings';
import HomeLayout from "./pages/HomeLayout";
import HomeBody from "./pages/HomeBody";
import JobSearch from "./pages/JobSearch";
import Jobs from "./pages/Jobs";
import JobDetail from "./pages/Job";
import { UserProvider } from './Context/UserContext';
import { TokenProvider } from './Context/TokenContext';
import { CandidateProvider } from './Context/CandidateContext';
import { AlertProvider } from './Context/AlertContext';
import AuthCallback from './components/AuthCallback';
import ProtectedRoute from "./utils/ProtectedRoute";
import AdditionalInfo from './pages/Candidate/CandidateInfoPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.css';
import './css/utilities.css';
import './js/main.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/talentup.css';

function Home() {
    return (
        <HomeLayout>
            <HomeBody />
        </HomeLayout>
    );
}

function AuthenticatedCandidate() {
    return (
        <CandidateLayout>
            <Routes>
                <Route path="/profile" element={<CandidateProfile />} />
                <Route path="/businessview" element={<CandidateBusinessView />} />
                <Route path="/jobs" element={<CandidateJobsView />} />
                <Route path="/settings" element={<CandidateSettingsView />} />
            </Routes>
        </CandidateLayout>

    );
}

function JobApplication() {
    return (
        <HomeLayout>
            <Routes>
                <Route path="/:jobId" element={<CandidateJobApply />} />
            </Routes>
        </HomeLayout>
    );
}

function App() {
    return (
        <AlertProvider>
            <TokenProvider>
                <UserProvider>
                    <CandidateProvider>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/auth/callback" element={<AuthCallback />} />
                            <Route path="/job-search" element={<JobSearch />} />
                            <Route path="/jobs" element={<HomeLayout><Jobs /></HomeLayout>} />
                            <Route path="/job/:jobId" element={<HomeLayout><JobDetail /></HomeLayout>} />
                            {/* Dynamic Routes for Jobs */}
                            <Route path="/jobapply/*" element={<ProtectedRoute><JobApplication /></ProtectedRoute>} />
                            <Route path="/candidate/*" element={<ProtectedRoute><AuthenticatedCandidate /></ProtectedRoute>} />
                            <Route path="/candidate/additional-info" element={
                                <ProtectedRoute>
                                    <AdditionalInfo />
                                </ProtectedRoute>
                            } />
                            <Route path="/error" element={<ErrorPage />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </CandidateProvider>
                </UserProvider>
            </TokenProvider>
        </AlertProvider>
    );
}

export default App;



