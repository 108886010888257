// Header.js
import React, { useState, useEffect } from 'react';
import logoImage from '../../src/images/Logo1-square2-white-transparent.png';
import { Outlet, Link } from "react-router-dom";
import JobSearch from '../pages/JobSearch';

function HomeBody() {
    useEffect(() => {
        document.title = "Find Jobs In Tech | TalentUp.com.au";
    }, []);
    const categories = [
        { name: "Network and Infrastructure", icon: "/path-to-icons/network.png" },
        { name: "Data Science and Analytics", icon: "/path-to-icons/data-science.png" },
        { name: "Cybersecurity", icon: "/path-to-icons/cybersecurity.png" },
        { name: "IT Project Management", icon: "/path-to-icons/project-management.png" },
        { name: "UI/UX Design", icon: "/path-to-icons/design.png" },
        { name: "Tech Support and Helpdesk", icon: "/path-to-icons/support.png" },
        // Add more categories as needed
    ];

    return (
        <>
            <JobSearch />
            <div className="container-xxl homebodytoplayer">
                <section className="HuSection container-fluid hilaki">
                    <div className="HuSection_createprofile_l_lasd p-3">
                        <h2 className="title_vt">Your Next Tech Job Awaits</h2>
                        <p>Discover your dream role in the tech industry with TalentUp. We connect you with technology jobs that align with your skills and career aspirations. Start exploring opportunities to propel your career forward.</p>
                        <div className="HuSection_bo_blocy mt-4">
                            <a href="" className="btn btn-primary">Create Your Profile</a>
                        </div>
                    </div>
                    <div className="HuSection_createprofile_r_lasd">
                        <div className="HuSection_createprofile_l_POlj">
                            <img src="" alt="home page create profile" />
                        </div>
                    </div>
                </section>

                <section className="HuSection_ho_23 mb-6">
                    <div className="container-fluid HuSection-inner-grb">
                        <div className="HuSection-inner-vt">
                            <p className="text-center title_vt">Find The Right Fit</p>
                            <div className="HuSection-inner-st">
                                <div className="d-flex gap-2 flex-wrap center-block">
                                    <form asp-controller="Jobs" asp-action="Search" method="Get" className="form_bu_asdfhhvf">
                                        <button className="secondary-font-color bg-transi bg-secondary bg-opacity-100 bg-opacity-100-hover rounded px-3 py-1 font-semibold text-heading text-m dark-grey-border m-2" name="what" value="C# Developer">C# Developer Jobs</button>
                                        <button className="secondary-font-color bg-transi bg-secondary bg-opacity-100 bg-opacity-100-hover rounded px-3 py-1 font-semibold text-heading text-m dark-grey-border m-2" name="what" value="Full Stack Developer">Full Stack Developer Jobs</button>
                                        <button className="secondary-font-color bg-transi bg-secondary bg-opacity-100 bg-opacity-100-hover rounded px-3 py-1 font-semibold text-heading text-m dark-grey-border m-2" name="what" value="Desktop Support Technician">Desktop Support Technician Jobs</button>
                                        <button className="secondary-font-color bg-transi bg-secondary bg-opacity-100 bg-opacity-100-hover rounded px-3 py-1 font-semibold text-heading text-m dark-grey-border m-2" name="what" value="Database Engineer">Database Engineer Jobs</button>
                                        <button className="secondary-font-color bg-transi bg-secondary bg-opacity-100 bg-opacity-100-hover rounded px-3 py-1 font-semibold text-heading text-m dark-grey-border m-2" name="what" value="IT Sales">IT Sales Jobs</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

         
                <div className="mid-but-tog container-fluid">
                    <section className="homepage-content-wrapper-outer">
                        <div className="homepage-content-wrapper-inner">
                            <p className="title_wt">Categories</p>
                            <p>Browse job listings across a wide range of categories. Whether you're an experienced professional or just starting out, we have something for everyone.</p>
                            <div className="row g-4 justify-content-center">
                                {categories.map((category, index) => (
                                    <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                                        <div className="card category-card h-100">
                                          {/*  <img src={category.icon} className="card-img-top" alt={category.name} />*/}
                                            <div className="card-body light-tal-pur-back">
                                                <h5 className="card-title">{category.name}</h5>
                                                <p className="card-text">Explore jobs and opportunities in {category.name}.</p>
                                                <Link to={`/jobs/${category.name}`} className="btn btn-primary">View Jobs</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        
                        </div>
                    </section>
                </div>

                <section className="HuSection container-fluid hilaki">
                    <div className="HuSection_createprofile_r_lasd p-3">
                        <div className="HuSection_createprofile_l_POlj">
                            <img src="" alt="home page create profile" />
                        </div>
                    </div>
                    <div className="HuSection_createprofile_l_lasd p-3">
                        <h2 className="title_vt">Employers: Find Top Tech Talent</h2>
                        <p>At TalentUp, we streamline the hiring process. Our platform connects you with skilled professionals ready to make an impact. Post your job openings today and meet your next great hire.</p>
                        <div className="HuSection_bo_blocy mt-4">
                            <a href="" className="btn btn-primary m-1">Post a job</a>
                            <a href="" className="btn btn-primary m-1">Hire top tech talent</a>
                        </div>
                    </div>
                  
                </section>

            </div>
        </>
    );
}

export default HomeBody;
